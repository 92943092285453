import { Button, Stack, Typography } from "@mui/material";

type Props = {
  width?: number | string;
  text: string;
  onClick?: React.MouseEventHandler;
  type?: "submit" | "reset" | "button";
  disabled?: boolean;
  fontWeight?: string;
};

const CustomButton = ({
  text,
  onClick,
  width = "fit-content",
  disabled = false,
  type = "button",
  fontWeight = "bold",
}: Props) => {
  return (
    <Stack>
      <Button
        variant="contained"
        onClick={onClick}
        type={type}
        sx={{
          width: width,
          m: "auto",
          p: "10px 16px",
          fontWeight: fontWeight,
          textTransform: "initial",
        }}
        disabled={disabled}
        color="primary"
      >
        <Typography fontSize={18}>{text}</Typography>
      </Button>
    </Stack>
  );
};

export default CustomButton;
