// @ts-nocheck
import { useMsal } from "@azure/msal-react";
import { AuthenticationResult } from "@azure/msal-browser";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

const useGetAccessToken = () => {
  const navigate: NavigateFunction = useNavigate();
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const redirectIfTokenExpired = (error) => {
    if (error.errorMessage.includes("AADB2C90077")) {
      navigate(`/logout`, { state: "LogoutWithoutClearCache" });
    }
  };

  if (accounts[0]) {
    instance
      .acquireTokenSilent({
        scopes: [process.env.REACT_APP_SCOPE_URL, "openid", "offline_access"],
        account: accounts[0],
      })
      .then((accessTokenResponse: AuthenticationResult) => {
        if (new Date(accessTokenResponse.expiresOn) < new Date()) {
          navigate(`/logout`, { state: "LogoutWithoutClearCache" });
        } else {
          setAccessToken(accessTokenResponse.accessToken);
        }
      })
      .catch((error) => {
        redirectIfTokenExpired(error as Msal.AuthError);
      });
  }

  return accessToken;
};

export default useGetAccessToken;
