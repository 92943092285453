export const CONFIG_API = {
  CTONLINE_ADMINISTRATION: "CTonlineAdministration",
  CTONLINE: "CTonline",
  CONNECT: "Connect",
  ORGANIZATION: "Organization",
  ORGANIZATION_PID: "organizationPid",
  CENTER: "Center",
  ACCOUNT: "Account",
  ADMIN: "Admin",
  USER: "User",
  LOGIN: "login",
  EXTERNAL_ACCOUNT: "ExternalAccount",
  EXTERNAL_USER_ID: "ExternalUserId",
  PASSWORD: "password",
  PROFILE: "Profile",
  REGISTER_ACCOUNT: "RegisterAccount",
};
