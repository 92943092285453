import { useEffect, useState } from "react";
import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { CircularProgress, Stack } from "@mui/material";

import { CONFIG_PARAMS_URL } from "../data/config.paramsURL";
import { CONFIG_SESSION_STORAGE } from "../data/config.sessionStorage";

import HomeCard from "../components/HomeCard";
import Error from "../components/CustomError";
import convertClientType from "../utils/authCTonline/convertClientType";
import generateSignature from "../utils/authCTonline/generateSignature";

const CheckURL = () => {
  const { instance, accounts } = useMsal();
  const navigate: NavigateFunction = useNavigate();
  const [searchParams]: [URLSearchParams, Function] = useSearchParams();
  const [error, setError] = useState<string | null>(null);

  // Get params from URL and set in params object
  let params = {
    org_code: searchParams.get(CONFIG_PARAMS_URL.ORG_CODE),
    client_type: searchParams.get(CONFIG_PARAMS_URL.CLIENT_TYPE),
    return_url: searchParams.get(CONFIG_PARAMS_URL.RETURN_URL),
    signature: searchParams.get(CONFIG_PARAMS_URL.SIGNATURE),
    sign_up: searchParams.get(CONFIG_PARAMS_URL.IS_SIGN_UP),
  };

  const { org_code, client_type, return_url, signature } = params;

  const handleSign = async (getAuthority: string) => {
    try {
      // Redirect to Azure ID where the user can sign in
      await instance.loginRedirect({
        authority: getAuthority,
        scopes: [
          process.env.REACT_APP_SCOPE_URL ?? "",
          "openid",
          "offline_access",
        ],
      });
    } catch (err: any) {
      console.error(err.message);
    }
  };

  // Set Params in sessionStorage Cache
  const setValidParams = () => {
    if (!org_code || !client_type || !return_url || !signature) {
      setError("L'URL est incorrecte {Login[50]}");
      console.error("Il manque des paramètres dans l'URI");
      return false;
    }
    // Check if connected user is trying to access from another link (with signature saved in sessionStorage), if so : logout
    if (sessionStorage.getItem(CONFIG_SESSION_STORAGE.PARAMS)) {
      let savedParams = JSON.parse(
        sessionStorage.getItem(CONFIG_SESSION_STORAGE.PARAMS) ?? "{}",
      );
      if (savedParams.signature !== signature) {
        instance.logoutRedirect({
          authority: process.env.REACT_APP_AZURE_BASE_URL,
          postLogoutRedirectUri: `/login?${CONFIG_PARAMS_URL.ORG_CODE}=${org_code}&${CONFIG_PARAMS_URL.CLIENT_TYPE}=${client_type}&${CONFIG_PARAMS_URL.RETURN_URL}=${return_url}&${CONFIG_PARAMS_URL.SIGNATURE}=${signature}`,
        });
      }
    }
    // Generate hashed signature
    let hashedSignature = generateSignature([
      client_type,
      org_code,
      return_url,
    ]);
    // Convert clientType in private
    params.client_type = convertClientType(
      client_type.toLowerCase(),
      "private",
    );
    if (hashedSignature === signature) {
      sessionStorage.setItem(
        CONFIG_SESSION_STORAGE.PARAMS,
        JSON.stringify(params),
      );
      // CHECK IF USER TYPE IS CLIENT
      if (params.client_type === "Client") {
        if (accounts[0]) {
          navigate("/", { replace: true });
        }
        // CHECK IF USER HAS CANCELED AZURE SIGN UP FORM
        let azureParamsString = localStorage.getItem(
          `server-telemetry-${process.env.REACT_APP_CLIENT_ID}`,
        );
        if (azureParamsString) {
          let azureParams = JSON.parse(azureParamsString);
          if (
            azureParams.errors.some(
              (error: string) => error === "access_denied",
            )
          ) {
            // CLEAR AZURE ERRORS AND REDIRECT TO ORIGINAL URL
            localStorage.removeItem(
              `server-telemetry-${process.env.REACT_APP_CLIENT_ID}`,
            );
            window.open(return_url, "_self");
          }
        } else {
          if (params.sign_up === "true") {
            // IF CLIENT USER COMES FROM SIGN UP BUTTON, REDIRECT TO AZURE SIGN UP FORM
            handleSign(process.env.REACT_APP_SIGNUP_AZURE_URL ?? "");
          } else {
            // IF CLIENT USER COMES FROM SIGN IN BUTTON, REDIRECT TO AZURE SIGN IN FORM
            handleSign(process.env.REACT_APP_AZURE_BASE_URL ?? "");
          }
        }
      } else {
        // IF USER TYPE ISN'T CLIENT, BASIC CONNECT PROCESS
        navigate("/", { replace: true });
      }
    } else {
      setError("La signature est invalide");
      console.error("La signature est invalide");
    }
  };

  useEffect(() => {
    setValidParams();
  }, [setValidParams]);

  return (
    <HomeCard>
      {!error ? (
        <Stack>
          <CircularProgress />
        </Stack>
      ) : (
        <Error message={error} />
      )}
    </HomeCard>
  );
};

export default CheckURL;
