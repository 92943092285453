interface Modes {
  [index: string]: string;
}

// Detect client type from typeName and convert it to the equivalent name defined by toType
const convertClientType = (typeName: string | undefined, toType: string) => {
  let fromType: string;
  let clientType: any;
  let response: string | undefined;

  const modes: Modes[] = [
    {
      old: "org",
      private: "Admin",
      number: "1",
      public: "Organisation",
    },
    {
      old: "emp",
      private: "User",
      number: "2",
      public: "Employé",
    },
    {
      old: "cli",
      private: "Client",
      number: "3",
      public: "client",
    },
  ];

  if (typeName) {
    modes.forEach((mode: any) => {
      response = Object.keys(mode).find((key) => mode[key] === typeName);
      response && (fromType = response);
    });
    clientType = modes.find((mode: any) => mode[fromType] === typeName);
  }

  if (clientType) {
    return clientType[toType];
  } else {
    console.error("Impossible de convertir ce mode de connexion");
    return null;
  }
};

export default convertClientType;
