import { useState } from "react";
import { Typography } from "@mui/material";

import ArrowDownIcon from "../assets/images/icons/arrow-down.svg";
import ArrowDownUp from "../assets/images/icons/arrow-up.svg";

import ErrorIcon from "../assets/images/icons/error.png";
import CustomIcon from "./CustomIcon";

type Props = {
  icon?: boolean;
  message?: string | null;
};

const CustomError = ({
  message = "Une erreur est survenue.",
  icon = true,
}: Props) => {
  const [showErrorDetails, setShowErrorDetails] = useState(false);

  return (
    <>
      {icon && (
        <CustomIcon iconName={ErrorIcon} alternativeText="Icône Erreur" />
      )}
      <Typography color="error" fontWeight="bold" fontSize={17}>
        {message === "noCache"
          ? "Veuillez utiliser votre raccourci habituel pour accéder à CTonline."
          : "Une erreur est survenue."}
      </Typography>
      {message !== "noCache" && (
        <img
          src={!showErrorDetails ? ArrowDownIcon : ArrowDownUp}
          alt="Déplier"
          width={20}
          style={{ margin: "auto", cursor: "pointer" }}
          onClick={() => setShowErrorDetails(!showErrorDetails)}
        />
      )}
      {showErrorDetails && (
        <Typography variant="subtitle2">{message}</Typography>
      )}
    </>
  );
};

export default CustomError;
