// @ts-nocheck
import { Typography, Stack, Box } from "@mui/material";
import { useMsal } from "@azure/msal-react";

import { ParamsDataModel } from "../pages/Home";
import procedurePDF from "../assets/documents/Procédure-CTonline-Connect.pdf";
import convertClientType from "../utils/authCTonline/convertClientType";

import CustomButton from "./CustomButton";
import Error from "./CustomError";

type Props = {
  paramsData: ParamsDataModel | null;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
};

const HomeText = ({ paramsData, setError }: Props) => {
  const { instance } = useMsal();
  let convertedClientMode: string | null = null;

  paramsData &&
    (convertedClientMode = convertClientType(paramsData.client_type, "public"));

  const handleSign = async (getAuthority: string) => {
    try {
      // Redirect to Azure ID where the user can sign in
      await instance.loginRedirect({
        authority: getAuthority,
        scopes: [process.env.REACT_APP_SCOPE_URL, "openid", "offline_access"],
      });
    } catch (err: any) {
      setError(true);
      console.error(err.message);
    }
  };

  return (
    <Stack margin="auto" px={0}>
      {convertedClientMode && (
        <Typography
          variant="h5"
          fontWeight="initial"
          fontSize={30}
          textAlign={"center"}
          mb={1}
          color="#361F4B"
        >
          {convertedClientMode}
        </Typography>
      )}
      <Typography lineHeight={1.3} fontSize={17} textAlign={"center"} mb={2}>
        CTonline utilise le nouveau service d'authentification CTonline Connect.
        Ce service fonctionne avec une adresse mail qui vous sera demandée.
      </Typography>
      <Stack sx={{ mb: 2 }}>
        {convertedClientMode && (
          <Typography lineHeight={1.3} fontSize={17} mb={0.5}>
            Si vous avez déjà utilisé CTonline Connect, cliquez sur «Connexion{" "}
            {convertedClientMode.toLowerCase()}»
          </Typography>
        )}
        <CustomButton
          width="16rem"
          text={
            convertedClientMode
              ? `Connexion ${convertedClientMode.toLowerCase()}`
              : "Connexion"
          }
          onClick={() => handleSign(process.env.REACT_APP_AZURE_BASE_URL)}
          disabled={!paramsData ? true : false}
          fontWeight="none"
        />
        {paramsData && (
          <>
            <Stack gap={0.5} mt={2}>
              <Typography lineHeight={1.3} fontSize={17}>
                Si c'est la première fois que vous utilisez CTonline Connect,
                cliquez sur «Créer un compte»
              </Typography>
              <CustomButton
                width="16rem"
                text={"Créer un compte"}
                onClick={() =>
                  handleSign(process.env.REACT_APP_SIGNUP_AZURE_URL)
                }
                fontWeight="none"
              />
            </Stack>
          </>
        )}
      </Stack>
      {paramsData ? (
        <>
          <Box marginLeft={1} textAlign={{ xs: "center", sm: "justify" }}>
            <Typography lineHeight={1.2}>
              Vous rencontrez des difficultés ?<br />
            </Typography>
            <Typography
              lineHeight={1.2}
              mb="0.5rem"
              sx={{
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
              }}
            >
              <a
                href={procedurePDF}
                target="_blank"
                rel="noreferrer noopener"
                style={{ color: "blue" }}
              >
                Consulter la procédure de connexion
              </a>
            </Typography>
          </Box>
        </>
      ) : (
        <Error icon={false} message="noCache" />
      )}
    </Stack>
  );
};

export default HomeText;
