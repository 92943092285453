import { Typography } from "@mui/material";

import ErrorScreenIcon from "../assets/images/icons/error-screen.png";
import CustomIcon from "../components/CustomIcon";
import HomeCard from "../components/HomeCard";

const Error404 = () => {
  return (
    <>
      <HomeCard>
        <CustomIcon
          iconName={ErrorScreenIcon}
          alternativeText="Icône Erreur 404"
        />
        <Typography color={"black"} fontWeight="bold">
          Erreur 404 : La page n'existe pas.
        </Typography>
      </HomeCard>
    </>
  );
};

export default Error404;
