import { AccountInfo } from "@azure/msal-browser";
import { ReactNode, useCallback, useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";

import { CONFIG_API } from "../data/config.API";
import { ParamsDataModel } from "../pages/Home";
import { ApiResponse } from "../models/ApiResponse";
import { User } from "../models/User";

import RocketIcon from "../assets/images/icons/rocket.png";
import Error from "./CustomError";
import LoadingProgress from "./LoadingProgress";
import SignInForm from "./SignInForm";
import convertClientType from "../utils/authCTonline/convertClientType";

type Props = {
  currentAccount: AccountInfo;
  accessToken: string;
  data: ParamsDataModel;
  setComponentToShow?: React.Dispatch<React.SetStateAction<ReactNode>>;
};

const Redirect = ({
  currentAccount,
  accessToken,
  data,
  setComponentToShow,
}: Props) => {
  const [error, setError] = useState<string | null>(null);

  // GET user profile from API and set required params for redirection
  const getUserProfile = useCallback(async () => {
    try {
      let convertedClientType;
      if (data.client_type) {
        if (data.client_type === "cli") {
          convertedClientType = convertClientType(data.client_type, "private");
        } else {
          // CAPITALIZE FIRST LETTER OF CLIENT_TYPE FOR API CORRECT PARAMETER
          convertedClientType =
            data.client_type.charAt(0).toUpperCase() +
            data.client_type.slice(1);
        }
      }
      let response: AxiosResponse<ApiResponse<User>, any> = await axios.get(
        `${process.env.REACT_APP_CTONLINE_API_URL}/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${data.organizationPID}/${CONFIG_API.ACCOUNT}/${convertedClientType}/${CONFIG_API.EXTERNAL_USER_ID}/${currentAccount.idTokenClaims?.sub}/${CONFIG_API.PROFILE}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "X-Version": process.env.REACT_APP_API_VERSION,
          },
        },
      );
      if (response.data.success) {
        if (response.data.item) {
          // Redirect to CTOnline Web V2
          window.location.href = `${process.env.REACT_APP_CTONLINE_WEB_URL}/connect-login?azure_account=${currentAccount.idTokenClaims?.sub}`;
        } else {
          if (setComponentToShow) {
            setComponentToShow(
              <SignInForm
                currentAccount={currentAccount}
                accessToken={accessToken}
                data={data}
                errorMessage="Cet utilisateur n'existe plus, veuillez relier à nouveau votre compte CTonline Connect."
                setComponentToShow={setComponentToShow}
              />,
            );
          }
        }
      } else {
        setError("Erreur API");
        response.data.errors.forEach((error: any) => {
          console.error(error);
        });
      }
    } catch (error: any) {
      setError(error.message);
      console.error(error.message);
    }
  }, [accessToken, currentAccount, data, setComponentToShow]);

  useEffect(() => {
    if (currentAccount) {
      getUserProfile();
    }
  }, [currentAccount, getUserProfile]);

  return error ? (
    <Error message={error} />
  ) : (
    <LoadingProgress message="Lancement CTonline..." iconName={RocketIcon} />
  );
};

export default Redirect;
