// @ts-nocheck
import { PublicClientApplication } from "@azure/msal-browser";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";

import { init as initApm } from "@elastic/apm-rum";
import { ThemeProvider } from "@emotion/react";
import customTheme from "./utils/customTheme/customTheme";

// Declared outside the component tree to avoid reinstantiation at rerendering
const PCA: any = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    knownAuthorities: [process.env.REACT_APP_KNOW_AUTHORITIES], // To authorize authority name
    authority: process.env.REACT_APP_AZURE_BASE_URL,
    redirectUri: `/`,
  },
  cache: {
    cacheLocation: "localStorage",
  },
});

initApm({
  serviceName: "CTonline_Connect_Web",
  serverUrl:
    "https://pts-elasticsearch.apm.francecentral.azure.elastic-cloud.com/",
  distributedTracingOrigins: [process.env.REACT_APP_CTONLINE_API_URL],
  logLevel: "debug",
  flushInterval: 100,
  disableInstrumentations: ["click"],
});

const root = ReactDOM.createRoot(document.getElementById("root") as Element); // as Element for Typescript Error
root.render(
  <ThemeProvider theme={customTheme}>
    <BrowserRouter>
      <App msalInstance={PCA} />
    </BrowserRouter>
  </ThemeProvider>,
);
