import { Stack, Typography } from "@mui/material";

import SettingsIcon from "../assets/images/icons/settings.png";
import CustomIcon from "./CustomIcon";

type Props = {
  iconName?: string;
  message?: string;
};

const LoadingProgress = ({
  iconName = SettingsIcon,
  message = "Chargement des données",
}: Props) => {
  return (
    <Stack color="primary.main" alignItems="center" gap={2} m={2}>
      {iconName && (
        <CustomIcon
          iconName={iconName}
          width="80rem"
          alternativeText="Icône Erreur"
        />
      )}
      <Typography fontWeight="bold" variant="h6" color={"black"}>
        {message}
      </Typography>
    </Stack>
  );
};

export default LoadingProgress;
