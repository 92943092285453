import { useEffect } from "react";
import { Location, Navigate, useLocation } from "react-router-dom";
import { Divider, Stack, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";

import { CONFIG_SESSION_STORAGE } from "../data/config.sessionStorage";
import { ParamsDataModel } from "./Home";
import Warning from "../assets/images/icons/warning.png";

import convertClientType from "../utils/authCTonline/convertClientType";
import HomeCard from "../components/HomeCard";
import CustomIcon from "../components/CustomIcon";

const WrongMode = () => {
  const { instance } = useMsal();
  let clientType: string;

  const location: Location = useLocation();

  // Get clientType to show after logout redirect
  if (sessionStorage.getItem(CONFIG_SESSION_STORAGE.PARAMS)) {
    let savedParams: ParamsDataModel = JSON.parse(
      sessionStorage.getItem(CONFIG_SESSION_STORAGE.PARAMS) ?? "{}",
    );
    clientType = convertClientType(savedParams.client_type, "public");
  } else {
    clientType = convertClientType(location.state, "public");
  }

  useEffect(() => {
    instance.logoutRedirect({
      authority: process.env.REACT_APP_AZURE_BASE_URL,
    });
  }, [clientType, instance]);

  return clientType ? (
    <HomeCard>
      <Stack gap={2} px={4}>
        <CustomIcon iconName={Warning} alternativeText="Icône Warning" />
        <Typography lineHeight={1.3} fontSize={18} color={"black"}>
          Vous avez essayé de vous connecter en mode {clientType}.
        </Typography>
        <Divider
          textAlign="center"
          sx={{ backgroundColor: "primary.main", width: "90%", m: "auto" }}
        />
        <Typography fontSize={18} lineHeight={1.3} color={"black"}>
          Veuillez utiliser votre raccourci habituel pour accéder à CTonline.
        </Typography>
      </Stack>
    </HomeCard>
  ) : (
    <Navigate replace to="/" />
  );
};

export default WrongMode;
