import { AccountInfo } from "@azure/msal-common";
import { ReactNode, useEffect, useState } from "react";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { CONFIG_SESSION_STORAGE } from "../data/config.sessionStorage";

import useGetAccessToken from "../hooks/useGetAccessToken";
import Error from "../components/CustomError";
import HomeCard from "../components/HomeCard";
import HomeText from "../components/HomeText";
import Account from "../components/Account";

export interface ParamsDataModel {
  org_code: string;
  client_type: string;
  return_url: string;
  signature: string;
  organizationPID?: string;
  user_pid?: string;
}

const Home = () => {
  // Declare useMsal hook to access the application instance
  const { accounts } = useMsal();

  const [paramsData, setParamsData] = useState<ParamsDataModel | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [currentAccount, setCurrentAccount] = useState<AccountInfo | null>(
    null,
  );
  const [componentToShow, setComponentToShow] = useState<ReactNode | null>(
    null,
  );
  let accessToken = useGetAccessToken();

  useEffect(() => {
    // If params in cache, set them in paramsData
    if (sessionStorage.getItem(CONFIG_SESSION_STORAGE.PARAMS)) {
      setParamsData(
        JSON.parse(sessionStorage.getItem(CONFIG_SESSION_STORAGE.PARAMS) ?? ""),
      );
    }
    // Refresh paramsData state depending on sessionStorage data
    const storageEventHandler = (event: { key: any; newValue: any }) => {
      if (event.key === CONFIG_SESSION_STORAGE.PARAMS) {
        setParamsData(JSON.parse(event.newValue));
      } else {
        setParamsData(null);
      }
    };
    // Listening sessionStorage : When it changes, storageEventHandler is executed
    window.addEventListener("storage", storageEventHandler);
    return () => {
      window.removeEventListener("storage", storageEventHandler);
    };
  }, []);

  // If logged, set currentAccount from accounts
  useEffect(() => {
    if (accounts[0]) {
      setCurrentAccount(accounts[0]);
    }
  }, [accounts]);

  // When currentAccount & accessToken, show Account component
  useEffect(() => {
    if (currentAccount && accessToken) {
      setComponentToShow(
        <Account
          currentAccount={currentAccount}
          accessToken={accessToken}
          data={paramsData}
          setComponentToShow={setComponentToShow}
        />,
      );
    }
  }, [currentAccount, accessToken]);

  return (
    <>
      <HomeCard animation={componentToShow !== null}>
        <>
          <AuthenticatedTemplate>
            {paramsData && componentToShow}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <HomeText paramsData={paramsData} setError={setError} />
          </UnauthenticatedTemplate>
          {error && <Error />}
        </>
      </HomeCard>
    </>
  );
};

export default Home;
