import { AccountInfo } from "@azure/msal-browser";
import axios, { AxiosResponse } from "axios";
import { ReactNode, useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { apm } from "@elastic/apm-rum";

import { CONFIG_SESSION_STORAGE } from "../data/config.sessionStorage";
import { CONFIG_API } from "../data/config.API";
import { ParamsDataModel } from "../pages/Home";
import { ApiResponse } from "../models/ApiResponse";

import Error from "./CustomError";
import LoadingProgress from "./LoadingProgress";
import UserLoadingIcon from "../assets/images/icons/user-loading.png";
import Redirect from "./Redirect";
import SignInForm from "./SignInForm";

export type Props = {
  currentAccount: AccountInfo;
  accessToken: string;
  data: ParamsDataModel | null;
  setComponentToShow: React.Dispatch<React.SetStateAction<ReactNode>>;
};

const Account = ({
  currentAccount,
  accessToken,
  data,
  setComponentToShow,
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const navigate: NavigateFunction = useNavigate();

  const [organizationPID, setOrganizationPID] = useState<
    string | null | undefined
  >(null);

  // Get Organization PID and set it in sessionStorage
  const getOrganizationPID = async () => {
    try {
      let apmLabels = { username: currentAccount.username, externalUserId: "" };
      currentAccount.idTokenClaims?.sub &&
        (apmLabels.externalUserId = currentAccount.idTokenClaims?.sub);
      apm.addLabels(apmLabels);
      let response: AxiosResponse<ApiResponse<string>, any> = await axios.get(
        `${process.env.REACT_APP_CTONLINE_API_URL}/${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.CENTER}/${CONFIG_API.ORGANIZATION}/${data?.org_code}/${CONFIG_API.ORGANIZATION_PID}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "X-Version": process.env.REACT_APP_API_VERSION,
          },
        },
      );
      if (response.data.success && response.data.item) {
        data && (data = { ...data, organizationPID: response.data.item });
        sessionStorage.setItem(
          CONFIG_SESSION_STORAGE.PARAMS,
          JSON.stringify(data),
        );
        setOrganizationPID(response.data.item);
        if (organizationPID) {
          isUserAlreadyKnown();
        }
      } else {
        setError("Aucun PID d'organisation");
        console.error("Aucun PID d'organisation");
      }
    } catch (error: any) {
      setError(error.message);
      console.error(error.message);
    }
  };

  // Request status to know if the user is registered in the database (200 or 404 response)
  const isUserAlreadyKnown = async () => {
    if (
      organizationPID &&
      data?.client_type &&
      currentAccount.idTokenClaims?.sub
    ) {
      try {
        let response: AxiosResponse<ApiResponse<null>, any> = await axios.head(
          `${process.env.REACT_APP_CTONLINE_API_URL}/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPID}/${CONFIG_API.ACCOUNT}/${data.client_type}/${currentAccount.idTokenClaims.sub}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "X-Version": process.env.REACT_APP_API_VERSION,
            },
          },
        );
        if (response.status === 200) {
          setComponentToShow(
            <Redirect
              currentAccount={currentAccount}
              accessToken={accessToken}
              data={data}
              setComponentToShow={setComponentToShow}
            />,
          );
        }
      } catch (error: any) {
        // If the account isn't in the right mode, check if the account exists in the other database
        if (error.response && error.response.status === 404) {
          let otherType: string;
          switch (data.client_type) {
            case "User":
              otherType = "Admin";
              break;
            case "Admin":
              otherType = "User";
              break;
            default:
              otherType = "Client";
              break;
          }
          try {
            let response: AxiosResponse<
              ApiResponse<null>,
              any
            > = await axios.head(
              `${process.env.REACT_APP_CTONLINE_API_URL}/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPID}/${CONFIG_API.ACCOUNT}/${otherType}/${currentAccount.idTokenClaims.sub}`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "X-Version": process.env.REACT_APP_API_VERSION,
                },
              },
            );
            if (response.status === 200) {
              navigate("/wrong-mode", { state: data.client_type });
            }
          } catch (error: any) {
            // The account is neither in the User or Admin database, redirect to post the GUID in the database
            if (error.response && error.response.status === 404) {
              setComponentToShow(
                <SignInForm
                  currentAccount={currentAccount}
                  accessToken={accessToken}
                  data={data}
                  setComponentToShow={setComponentToShow}
                />,
              );
            } else {
              setError(error.message);
              console.error(error.message);
            }
          }
        } else {
          setError(error.message);
          console.error(error.message);
        }
      }
    } else {
      setError("L'URL est incorrecte {Account [121]}");
      console.error(
        "Les paramètres à envoyer à la requête ne peuvent pas être null",
      );
    }
  };

  // When data & accessToken are declared, executes getOrganizationPID
  useEffect(() => {
    if (accessToken) {
      getOrganizationPID();
    }
  }, [getOrganizationPID]);

  return !error ? (
    <LoadingProgress
      iconName={UserLoadingIcon}
      message={"Vérification du compte..."}
    />
  ) : (
    <Error message={error} />
  );
};

export default Account;
