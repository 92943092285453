export const CONFIG_PARAMS_URL = {
  CLIENT_TYPE: "client_type",
  RETURN_URL: "return_url",
  ORG_CODE: "org_code",
  SIGNATURE: "signature",
  IS_SUPERADMINISTRATOR: "isProtech",
  IS_CTONLINE_LIMITED: "isLimited",
  USERNAME: "userName",
  LOGINTYPE: "loginType",
  TIMESTAMP: "connexionTimeStamp",
  ORGANIZATION_CODE: "organisationCode",
  IS_SIGN_UP: "sign_up",
  USER_PID: "user_pid",
};
