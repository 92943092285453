import { Navigate } from "react-router-dom";
import generateSignature from "../utils/authCTonline/generateSignature";

const OldWebsite = () => {
  let org_code = "74ctse";
  let client_type = "emp";
  let return_url = "http://74ctse.ct-online.fr/74ctse/PL2ForRdv.aspx";
  let sign_up = "false";
  // let user_pid = "f8395644-8102-448e-9ff8-6a4f58f122d2"

  let signature = generateSignature([client_type, org_code, return_url]);

  // Regular URI
  let URI = `/login?org_code=${org_code}&client_type=${client_type}&return_url=${return_url}&signature=${signature}&sign_up=${sign_up}`;

  // Sign Up URI
  // let URI = `/signup?org_code=${org_code}&client_type=${client_type}&return_url=${return_url}&user_pid=${user_pid}&signature=${signature}`;

  return <Navigate to={URI} />;
};

export default OldWebsite;
