import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { CircularProgress, Stack } from "@mui/material";

import useGetAccessToken from "../../hooks/useGetAccessToken";
import generateSignature from "../../utils/authCTonline/generateSignature";
import convertClientType from "../../utils/authCTonline/convertClientType";
import { ApiResponse } from "../../models/ApiResponse";
import { CONFIG_API } from "../../data/config.API";
import { CONFIG_PARAMS_URL } from "../../data/config.paramsURL";

import Error from "../../components/CustomError";
import HomeCard from "../HomeCard";

const PostAccount = () => {
  const [error, setError] = useState<string | null>(null);
  const [searchParams]: [URLSearchParams, Function] = useSearchParams();

  // Get params from URL and set in params object
  let params = {
    org_code: searchParams.get(CONFIG_PARAMS_URL.ORG_CODE) ?? "",
    client_type: searchParams.get(CONFIG_PARAMS_URL.CLIENT_TYPE) ?? "",
    return_url: searchParams.get(CONFIG_PARAMS_URL.RETURN_URL) ?? "",
    signature: searchParams.get(CONFIG_PARAMS_URL.SIGNATURE) ?? "",
    user_pid: searchParams.get(CONFIG_PARAMS_URL.USER_PID) ?? "",
    organizationPID: "",
  };

  const { org_code, client_type, return_url, signature, user_pid } = params;

  const { accounts } = useMsal();
  let accessToken = useGetAccessToken();

  // PUT REQUEST to link internaUserId (user_pid) with externalUserId in the database
  const linkAccount = useCallback(
    async (organizationPID: string) => {
      try {
        let response: AxiosResponse<
          ApiResponse<boolean>,
          any
        > = await axios.put(
          `${process.env.REACT_APP_CTONLINE_API_URL}/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPID}/${CONFIG_API.ACCOUNT}/${params.client_type}/${CONFIG_API.REGISTER_ACCOUNT}`,
          {
            externalUserId: accounts[0].idTokenClaims?.sub,
            internalUserPid: user_pid,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
              "X-Version": process.env.REACT_APP_API_VERSION,
            },
          },
        );
        if (response.data.success) {
          window.location.href = `${process.env.REACT_APP_CTONLINE_WEB_URL}/connect-login?azure_account=${accounts[0].idTokenClaims?.sub}`;
        } else {
          setError("Erreur API ");
          console.error(response.data.errors[0].message);
        }
      } catch (err: any) {
        console.error(err.message);
        setError("Une erreur est survenue.");
        return false;
      }
    },
    [accessToken, accounts, client_type, user_pid, setError],
  );

  // Get Organization PID
  const getOrganizationPID = useCallback(async () => {
    if (accessToken) {
      if (!org_code || !client_type || !return_url || !signature || !user_pid) {
        setError("L'URL est incorrecte {PostAccount[75]}");
        console.error("Il manque des paramètres dans l'URI");
        return false;
      }
      let hashedSignature = generateSignature([
        client_type,
        org_code,
        return_url,
      ]);
      if (hashedSignature === signature) {
        try {
          let response: AxiosResponse<
            ApiResponse<string>,
            any
          > = await axios.get(
            `${process.env.REACT_APP_CTONLINE_API_URL}/${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.CENTER}/${CONFIG_API.ORGANIZATION}/${org_code}/${CONFIG_API.ORGANIZATION_PID}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "X-Version": process.env.REACT_APP_API_VERSION,
              },
            },
          );
          if (response.data.success && response.data.item) {
            linkAccount(response.data.item);
          } else {
            setError("Aucun PID d'organisation");
            console.error("Aucun PID d'organisation");
          }
        } catch (error: any) {
          setError(error.message);
          console.error(error.message);
        }
      } else {
        setError("La signature est invalide");
        console.error("La signature est invalide");
      }
    }
  }, [accessToken, org_code, linkAccount, setError]);

  // CHECK IF USER HAS CANCELED AZURE SIGN UP FORM
  useEffect(() => {
    let azureParamsString = localStorage.getItem(
      `server-telemetry-${process.env.REACT_APP_CLIENT_ID}`,
    );
    if (azureParamsString) {
      let azureParams = JSON.parse(azureParamsString);
      if (
        azureParams.errors.some((error: string) => error === "access_denied")
      ) {
        // CLEAR AZURE ERRORS AND REDIRECT TO ORIGINAL URL
        localStorage.removeItem(
          `server-telemetry-${process.env.REACT_APP_CLIENT_ID}`,
        );
        window.open(return_url, "_self");
      }
    }
  }, [
    localStorage.getItem(`server-telemetry-${process.env.REACT_APP_CLIENT_ID}`),
  ]);

  useEffect(() => {
    if (params.client_type) {
      if (params.client_type === "cli") {
        params = {
          ...params,
          client_type: convertClientType(params.client_type, "private"),
        };
      } else {
        // CAPITALIZE FIRST LETTER OF CLIENT_TYPE FOR API CORRECT PARAMETER
        params = {
          ...params,
          client_type:
            client_type.charAt(0).toUpperCase() + client_type.slice(1),
        };
      }
      getOrganizationPID();
    } else {
      setError("L'URL est incorrecte {PostAccount[112]}");
      console.error("Il manque des paramètres dans l'URI");
    }
  }, [getOrganizationPID, localStorage]);

  return (
    <HomeCard>
      {!error ? (
        <Stack margin="auto">
          <CircularProgress />
        </Stack>
      ) : (
        <Error message={error} />
      )}
    </HomeCard>
  );
};

export default PostAccount;
