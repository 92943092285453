import axios, { AxiosResponse } from "axios";
import { AccountInfo } from "@azure/msal-browser";
import { useEffect, useState, ReactNode } from "react";
import {
  Box,
  Stack,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ParamsDataModel } from "../pages/Home";
import { ApiResponse } from "../models/ApiResponse";
import Eye from "../assets/images/icons/eye.png";
import Blind from "../assets/images/icons/blind.png";

import Error from "./CustomError";
import Redirect from "./Redirect";
import CustomButton from "./CustomButton";
import CustomIcon from "./CustomIcon";
import { CONFIG_API } from "../data/config.API";
import convertClientType from "../utils/authCTonline/convertClientType";

type Props = {
  currentAccount: AccountInfo;
  accessToken: string;
  data: ParamsDataModel;
  errorMessage?: string;
  setComponentToShow: React.Dispatch<React.SetStateAction<ReactNode>>;
};

type Inputs = {
  login: string;
  password: string;
  externalUserId?: string;
};

const SignInForm = ({
  currentAccount,
  accessToken,
  data,
  errorMessage,
  setComponentToShow,
}: Props) => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);
  const [externalUserId, setExternalUserId] = useState<string | undefined>();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const convertedClientMode: string = convertClientType(
    data.client_type,
    "public",
  );

  // SET externalUserId from user.sub only if an account is logged
  useEffect(() => {
    currentAccount && setExternalUserId(currentAccount.idTokenClaims?.sub);
  }, [currentAccount]);

  // DECLARE states from useForm() hook
  const { register, handleSubmit } = useForm<Inputs>();

  const cancelonClick = () => {
    if (data.client_type === "Client") {
      window.open(data.return_url, "_self");
    } else {
      navigate(`/logout`, { state: "LogoutWithoutClearCache" });
    }
  };

  // POST REQUEST to send login, password and externalUserId to the database
  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    // SET externalUserId in the formData object
    formData = { ...formData, externalUserId };
    try {
      let response: AxiosResponse<ApiResponse<boolean>, any> = await axios.post(
        `${process.env.REACT_APP_CTONLINE_API_URL}/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${data.organizationPID}/${CONFIG_API.ACCOUNT}/${data.client_type}/${CONFIG_API.EXTERNAL_ACCOUNT}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            "X-Version": process.env.REACT_APP_API_VERSION,
          },
        },
      );
      if (response.data.success) {
        setComponentToShow(
          <Redirect
            currentAccount={currentAccount}
            accessToken={accessToken}
            data={data}
            setComponentToShow={setComponentToShow}
          />,
        );
      } else {
        setApiError(response.data.errors[0].message);
      }
    } catch (err: any) {
      console.error(err.message);
      setError("Une erreur est survenue.");
    }
  };

  return (
    <>
      <Typography
        fontFamily={["Segoe UI", "Open Sans"]}
        fontSize="14px"
        fontWeight="initial"
        color="grey"
        m="1rem 1rem 0"
        sx={{
          ":hover": { color: "black" },
          cursor: "pointer",
          position: "absolute",
          top: "0",
          left: "0",
        }}
        onClick={cancelonClick}
      >
        <span style={{ fontFamily: "Ghotam", fontWeight: "bold" }}>{"<"}</span>{" "}
        Annuler
      </Typography>
      <Stack alignItems="center" px={4}>
        <Typography variant="h5" textAlign={"center"} mb={2}>
          Connexion
        </Typography>
        <Typography textAlign={"justify"} fontSize={18} lineHeight={1.3} color={"black"}>
          {!errorMessage
            ? `Vous devez maintenant lier votre nouveau compte CTonline Connect basé sur l'adresse de messagerie avec votre ancien compte ${convertedClientMode.toLowerCase()}.`
            : errorMessage}
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: "90%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            m: "1.5rem auto",
          }}
        >
          <TextField
            size="small"
            required
            autoFocus
            label="Ancien identifiant CTonline"
            {...register("login")}
          />
          <TextField
            size="small"
            type={!showPassword ? "password" : "text"}
            label="Mot de passe"
            {...register("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => setShowPassword(!showPassword)}
                  sx={{ cursor: "pointer" }}
                >
                  <CustomIcon
                    iconName={showPassword ? Blind : Eye}
                    isMarginAuto={false}
                    width="30px"
                    alternativeText="Montrer Password"
                  />
                </InputAdornment>
              ),
            }}
          />
          {error && <Error icon={false} message={error} />}
          {apiError && (
            <Typography color="error" fontWeight="bold" fontSize={17}>
              {apiError}
            </Typography>
          )}
          <CustomButton text="Connexion CTonline" type="submit" />
        </Box>
      </Stack>
    </>
  );
};

export default SignInForm;
