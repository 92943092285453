import { createTheme } from "@mui/material";

import "../../index.css";

const customTheme = createTheme({
  palette: {
    primary: { main: "#1C64F2" },
    secondary: { main: "#0063BA" },
  },
  typography: {
    fontFamily: ["Ghotam", "Segoe UI", "Open Sans"].join(","),
    fontWeightBold: 800,
    fontWeightRegular: 400,
    h5: {
      fontSize: 26,
      fontWeight: "bold",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1100,
      lg: 1300,
      xl: 1536,
    },
  },
});

export default customTheme;
