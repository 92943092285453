// @ts-nocheck
import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useLocation, useSearchParams } from "react-router-dom";

import { CONFIG_PARAMS_URL } from "../data/config.paramsURL";
import { CONFIG_SESSION_STORAGE } from "../data/config.sessionStorage";

import HomeCard from "../components/HomeCard";
import LoadingProgress from "../components/LoadingProgress";

const Logout = () => {
  const location = useLocation();
  const [searchParams]: [URLSearchParams, Function] = useSearchParams();
  const { instance } = useMsal();
  let URI = searchParams.get(CONFIG_PARAMS_URL.RETURN_URL);

  useEffect(() => {
    // Update URI Redirect if User isn't Client Type
    if (searchParams.get(CONFIG_PARAMS_URL.CLIENT_TYPE) !== "cli") {
      // To slice the first character ("?" before "login") to return a correct redirection URI ("/login")
      URI = `/${location.search.slice(1)}`;
    }
    if (location.state !== "LogoutWithoutClearCache") {
      sessionStorage.removeItem(CONFIG_SESSION_STORAGE.PARAMS);
    }
    instance.logout({
      authority: process.env.REACT_APP_AZURE_BASE_URL,
      postLogoutRedirectUri: `${URI}`,
    });
  }, [URI, instance]);

  return (
    <HomeCard>
      <LoadingProgress message="Déconnexion en cours" />
    </HomeCard>
  );
};

export default Logout;
