import { ReactNode } from "react";
import { Fade, Paper, Stack, Typography } from "@mui/material";

import CTonlineConnectLogo from "../assets/images/ctonline-connect-logo.svg";
import packageJson from "../../package.json";

type Props = {
  children: ReactNode;
  animation?: boolean;
};

const HomeCard = ({ children, animation = false }: Props) => {
  let timeout = 0;
  animation && (timeout = 750);

  return (
    <Stack height="100vh">
      <Fade in timeout={timeout}>
        <Paper
          sx={{
            position: "relative",
            m: "auto",
            boxShadow: "none",
            border: { xs: "none", sm: "1px solid #ddd" },
            borderRadius: "5px",
            width: { xs: "95%", sm: "70%", md: "30rem" },
          }}
        >
          <Stack
            minHeight="35rem"
            alignItems="center"
            pt={{ xs: 0, sm: "30px" }}
          >
            <img
              src={CTonlineConnectLogo}
              height={70}
              alt="Logo CTonline Connect"
            />
            <Stack
              justifyContent="center"
              textAlign="center"
              m="auto"
              p="0rem 1rem"
            >
              {children}
            </Stack>
            <Typography
              variant="subtitle2"
              width={"100%"}
              textAlign={"right"}
              color={"grey"}
              paddingX={"0.5rem"}
            >
              v.{packageJson.version}
            </Typography>
          </Stack>
          <Stack
            sx={{
              display: { xs: "none", sm: "flex" },
              justifySelf: "flex-end",
              backgroundColor: "primary.main",
              width: "100%",
              height: "1.5rem",
              borderRadius: "0 0 5px 5px",
            }}
          />
        </Paper>
      </Fade>
    </Stack>
  );
};

export default HomeCard;
