import { Routes, Route } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { CssBaseline } from "@mui/material";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Error404 from "./pages/Error404";
import WrongMode from "./pages/WrongMode";
import Signup from "./pages/Signup";
import PostAccount from "./components/signup/PostAccount";
import OldWebsite from "./pages/OldWebsite";

function App({ msalInstance }: any) {
  return (
    // Wrapping the App to access to the authentication state from every component
    <MsalProvider instance={msalInstance}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/link-account" element={<PostAccount />} />
        <Route path="/old" element={<OldWebsite />} />
        <Route path="/wrong-mode" element={<WrongMode />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </MsalProvider>
  );
}

export default App;
