import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { CircularProgress, Stack } from "@mui/material";

import { CONFIG_PARAMS_URL } from "../data/config.paramsURL";

import Error from "../components/CustomError";
import HomeCard from "../components/HomeCard";

const Signup = () => {
  const [searchParams]: [URLSearchParams, Function] = useSearchParams();
  const { instance, accounts, inProgress } = useMsal();
  const [error, setError] = useState<string | null>(null);

  // Get params from URL and set in params object
  let params = {
    org_code: searchParams.get(CONFIG_PARAMS_URL.ORG_CODE) ?? "",
    client_type: searchParams.get(CONFIG_PARAMS_URL.CLIENT_TYPE) ?? "",
    return_url: searchParams.get(CONFIG_PARAMS_URL.RETURN_URL) ?? "",
    signature: searchParams.get(CONFIG_PARAMS_URL.SIGNATURE) ?? "",
    user_pid: searchParams.get(CONFIG_PARAMS_URL.USER_PID) ?? "",
    organizationPID: "",
  };

  const { org_code, client_type, return_url, signature, user_pid } = params;

  const handleSign = useCallback(
    async (getAuthority: string) => {
      try {
        // Redirect to Azure ID where the user can sign in
        await instance.loginRedirect({
          authority: getAuthority,
          scopes: [
            process.env.REACT_APP_SCOPE_URL ?? "",
            "openid",
            "offline_access",
          ],
          redirectStartPage: `/link-account?${CONFIG_PARAMS_URL.ORG_CODE}=${org_code}&${CONFIG_PARAMS_URL.CLIENT_TYPE}=${client_type}&${CONFIG_PARAMS_URL.RETURN_URL}=${return_url}&${CONFIG_PARAMS_URL.SIGNATURE}=${signature}&${CONFIG_PARAMS_URL.USER_PID}=${user_pid}`,
        });
      } catch (err: any) {
        console.error(err.message);
      }
    },
    [client_type, instance, org_code, return_url, signature, user_pid],
  );

  // Set Params in sessionStorage Cache
  const checkIsUserAlreadyLogged = useCallback(() => {
    // IF ALREADY LOGGED, LOGOUT USER
    if (accounts[0]) {
      instance.logout({
        authority: process.env.REACT_APP_AZURE_BASE_URL,
        postLogoutRedirectUri: `/signup?${CONFIG_PARAMS_URL.ORG_CODE}=${org_code}&${CONFIG_PARAMS_URL.CLIENT_TYPE}=${client_type}&${CONFIG_PARAMS_URL.RETURN_URL}=${return_url}&${CONFIG_PARAMS_URL.SIGNATURE}=${signature}&${CONFIG_PARAMS_URL.USER_PID}=${user_pid}`,
      });
    } else {
      if (org_code && return_url && client_type && user_pid && signature) {
        handleSign(process.env.REACT_APP_SIGNUP_AZURE_URL ?? "");
      } else {
        setError("L'URL est incorrecte {Signup[69]}");
        console.error("Il manque des paramètres dans l'URI");
      }
    }
  }, [
    accounts,
    instance,
    handleSign,
    return_url,
    client_type,
    org_code,
    signature,
    user_pid,
  ]);

  useEffect(() => {
    if (inProgress === "none") {
      checkIsUserAlreadyLogged();
    }
  }, [inProgress, accounts, checkIsUserAlreadyLogged]);

  return (
    <HomeCard>
      {!error ? (
        <Stack margin={"auto"}>
          <CircularProgress />
        </Stack>
      ) : (
        <Error message={error} />
      )}
    </HomeCard>
  );
};

export default Signup;
