type Props = {
  isMarginAuto?: boolean;
  iconName: string;
  alternativeText: string;
  width?: number | string;
};

const CustomIcon = ({
  iconName,
  alternativeText,
  isMarginAuto = true,
  width = "80rem",
}: Props) => {
  let style: {
    margin: string;
  } = {
    margin: "initial",
  };

  isMarginAuto && (style.margin = "auto");

  return (
    <img src={iconName} alt={alternativeText} width={width} style={style} />
  );
};

export default CustomIcon;
